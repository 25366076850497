import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormContainer } from "../../Components/FormContainer.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { AutoSuggestInput, Input } from "../../Components/Input.js";
import { Msg } from "./Types.js";
import { defaultArg, unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { FormState__getValidation } from "../../Shared/Shared.js";
import { Feature, isFeatureEnabled } from "../../FeatureFlip.js";
import { useReact_useMemo_10C6A43C } from "../../fable_modules/Feliz.2.7.0/React.fs.js";
import { NewReceiverCustomProperties } from "../../Widgets/CustomProperties.js";
import { PropertyConfigurationVisibility } from "../../Shared/PropertyConfiguration.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { initEdit, update as update_1, initNew } from "./State.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { unwrapUserId } from "../../Shared/Helper.js";

export function EmployeeForm(props) {
    const t = useTranslation()[0];
    const formState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(FormContainer, {
        Children: toList(delay(() => append(singleton(createElement(Input, {
            ComponentState: formState,
            Label: t("general.name"),
            OnChange: (arg) => {
                props.Dispatch(new Msg(0, [arg]));
            },
            TestId: "",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "name")),
            Value: props.Employee.Name,
        })), delay(() => append(singleton(createElement(Input, {
            ComponentState: formState,
            Label: t("user.personnel_number"),
            OnChange: (arg_1) => {
                props.Dispatch(new Msg(1, [arg_1]));
            },
            TestId: "",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "personnel_number")),
            Value: props.Employee.PersonnelNumber,
        })), delay(() => {
            let Label_2, Value_2;
            return append(isFeatureEnabled(new Feature(15, [])) ? singleton(createElement(Input, (Label_2 = t("general.email"), (Value_2 = defaultArg(props.Employee.Email, ""), {
                ComponentState: formState,
                Label: unwrap(Label_2),
                OnChange: (arg_2) => {
                    props.Dispatch(new Msg(3, [arg_2]));
                },
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "email")),
                Value: Value_2,
            })))) : empty(), delay(() => append(singleton(useReact_useMemo_10C6A43C(() => createElement(AutoSuggestInput, {
                ComponentState: formState,
                Label: t("user.department"),
                OnChange: (arg_3) => {
                    props.Dispatch(new Msg(2, [arg_3]));
                },
                Suggestions: props.DepartmentSuggestions,
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "department")),
                Value: props.Employee.Department,
            }), [props.Employee.Department, props.DepartmentSuggestions, formState])), delay(() => (props.IsCustomPropertiesEnabled ? singleton(createElement(NewReceiverCustomProperties, {
                CustomProperties: props.Employee.CustomProperties,
                FormState: props.FormState,
                OnSetCustomProperty: (arg_4) => {
                    props.Dispatch(new Msg(4, [arg_4]));
                },
                PropertyConfigurationType: new PropertyConfigurationVisibility(3, []),
                PropertyConfigurations: props.PropertyConfigurations,
            })) : empty())))));
        })))))),
        Header: props.Header,
        OnBack: props.OnBack,
        OnSave: props.OnSave,
    });
}

export function NewEmployeeView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(initNew, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    const matchValue_1 = state_1.PropertyConfigurations;
    const matchValue_2 = state_1.IsCustomPropertiesEnabled;
    const matchValue_3 = state_1.Departments;
    let matchResult, departments, employee, isCustomPropertiesEnabled, propertyConfigurations;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        departments = matchValue_3.fields[0];
        employee = matchValue.fields[0];
        isCustomPropertiesEnabled = matchValue_2.fields[0];
        propertyConfigurations = matchValue_1.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(EmployeeForm, {
                DepartmentSuggestions: departments,
                Dispatch: dispatch,
                Employee: employee,
                FormState: state_1.FormState,
                Header: patternInput_1[0]("employee.create_new_employee"),
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled,
                OnBack: () => {
                    RouterModule_nav(ofArray(["receivers", "employees"]), 1, 1);
                },
                OnSave: () => {
                    dispatch(new Msg(5, []));
                },
                PropertyConfigurations: propertyConfigurations,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

export function EditEmployeeView(props) {
    let patternInput;
    const init = initEdit(props.EmployeeId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.EmployeeId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    const matchValue_1 = state_1.PropertyConfigurations;
    const matchValue_2 = state_1.IsCustomPropertiesEnabled;
    const matchValue_3 = state_1.Departments;
    let matchResult, departments, employee, isCustomPropertiesEnabled, propertyConfigurations;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        departments = matchValue_3.fields[0];
        employee = matchValue.fields[0];
        isCustomPropertiesEnabled = matchValue_2.fields[0];
        propertyConfigurations = matchValue_1.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(EmployeeForm, {
                DepartmentSuggestions: departments,
                Dispatch: dispatch,
                Employee: employee,
                FormState: state_1.FormState,
                Header: patternInput_1[0]("employee.edit_employee"),
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled,
                OnBack: () => {
                    RouterModule_nav(ofArray(["receivers", unwrapUserId(props.EmployeeId)]), 1, 1);
                },
                OnSave: () => {
                    dispatch(new Msg(6, []));
                },
                PropertyConfigurations: propertyConfigurations,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

