import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { equals, createObj } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { toArray, singleton, append, empty, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { SelectedTab, ToolboxControl } from "../../../Widgets/ToolboxControlWidget.js";
import { TableControl$1, OnTableRowClick$1, ColumnDefinition$1, CellContent } from "../../../Widgets/Table/Table.js";
import { Feature, isFeatureEnabled } from "../../../FeatureFlip.js";
import { Table_customPropertyColumnDefinitions } from "../../../Widgets/CustomProperties.js";
import { InfiniteTable } from "../../../Widgets/Table/InfiniteTable.js";
import { filter, ofArray, singleton as singleton_1, toArray as toArray_1 } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrap } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { RouterModule_nav, RouterModule_encodeParts } from "../../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import User_Add_Plus from "../../../../public/assets/icons/User_Add_Plus.svg";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.2/Map.js";
import { Msg } from "./Types.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { PropertyConfigurationVisibility } from "../../../Shared/PropertyConfiguration.js";
import { Skeleton } from "../../../Components/Skeleton.js";

export function StudentOverviewTable(props) {
    let elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-full"])], (elems = toList(delay(() => {
        const matchValue = props.UserData.Role;
        switch (matchValue) {
            case "user": {
                return empty();
            }
            default:
                return append(singleton(createElement(ToolboxControl, {
                    CustomerConfiguration: props.CustomerConfiguration,
                    SelectedTab: new SelectedTab(8, []),
                    UserData: props.UserData,
                })), delay(() => {
                    let TableRows, Options, matchValue_3;
                    const columnDefinitions = toArray(delay(() => append(singleton(new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text")), delay(() => append(isFeatureEnabled(new Feature(15, [])) ? singleton(new ColumnDefinition$1("email", "", t("general.email"), (dto_1) => {
                        const matchValue_1 = dto_1.Email;
                        return (matchValue_1 == null) ? (new CellContent(0, [""])) : (new CellContent(0, [matchValue_1]));
                    }, "text", "text")) : empty(), delay(() => append(singleton(new ColumnDefinition$1("studentNumber", "", t("user.student_number"), (dto_2) => (new CellContent(0, [dto_2.StudentNumber])), "text", "text")), delay(() => append(isFeatureEnabled(new Feature(6, [])) ? singleton(new ColumnDefinition$1("archived", "", t("user.is_archived"), (dto_3) => (new CellContent(0, [dto_3.IsArchived ? t("general.yes") : t("general.no")])), "select", "text")) : empty(), delay(() => Table_customPropertyColumnDefinitions(props.PropertyConfigurations, t("tool.custom_properties"), (dto_4) => dto_4.CustomProperties, t)))))))))));
                    return singleton(createElement(InfiniteTable, (TableRows = toArray_1(props.Students), (Options = {
                        BorderStyle: "row",
                        CSVDownload: true,
                        ConfigurableColumns: true,
                        DefaultColumnFilter: unwrap(isFeatureEnabled(new Feature(6, [])) ? singleton_1({
                            id: "archived",
                            value: [t("general.no")],
                        }) : undefined),
                        DefaultSortColumn: ["name", "asc"],
                        GlobalSearch: true,
                        OnRowClick: new OnTableRowClick$1(1, [(dto_5) => RouterModule_encodeParts(ofArray(["receivers", dto_5.Id]), 1)]),
                        RowSelection: "disabled",
                        WithQueryParameters: true,
                    }, {
                        ColumnDefinitions: columnDefinitions,
                        Controls: toList(delay(() => {
                            const matchValue_2 = props.UserData.Role;
                            switch (matchValue_2) {
                                case "toolManager":
                                case "user": {
                                    return empty();
                                }
                                default:
                                    return singleton(new TableControl$1(t("student.create_new_student"), User_Add_Plus(), (_arg) => {
                                        RouterModule_nav(ofArray(["receivers", "students", "new"]), 1, 1);
                                    }, false, false, ""));
                            }
                        })),
                        Options: Options,
                        TableConfiguration: unwrap((matchValue_3 = FSharpMap__TryFind(props.UserConfiguration.TableConfiguration, "receiver-student-overview-table"), (matchValue_3 == null) ? {
                            OnSaveTableConfiguration: (arg_1) => {
                                props.Dispatch(new Msg(5, [arg_1]));
                            },
                        } : {
                            OnSaveTableConfiguration: (arg) => {
                                props.Dispatch(new Msg(5, [arg]));
                            },
                            TableConfiguration: matchValue_3,
                        })),
                        TableRows: TableRows,
                    }))));
                }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function StudentOverviewView(props) {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.Students;
    const matchValue_1 = state_1.CustomerConfiguration;
    const matchValue_2 = state_1.PropertyConfigurations;
    const matchValue_3 = state_1.UserConfiguration;
    let matchResult, customerConfiguration, propertyConfigurations, students, userConfiguration;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        customerConfiguration = matchValue_1.fields[0];
        propertyConfigurations = matchValue_2.fields[0];
        students = matchValue.fields[0];
        userConfiguration = matchValue_3.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(StudentOverviewTable, {
                CustomerConfiguration: customerConfiguration,
                Dispatch: patternInput[1],
                PropertyConfigurations: filter((propertyConfiguration) => equals(propertyConfiguration.Visibility, new PropertyConfigurationVisibility(10, [])), propertyConfigurations),
                Students: students,
                UserConfiguration: userConfiguration,
                UserData: props.UserData,
            });
        default:
            return createElement(Skeleton, {
                Variant: "table",
            });
    }
}

